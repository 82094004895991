<template>
  <div>
    Clientes
  </div>
</template>
<script>

import store from '../../store'

export default {
  name: 'Customers',
  components: {
    
  },
  data () {
    return {
      
    }
  },
  mounted: async function() {

  },
  methods: {
    
  }
}
</script>
